import React from 'react'
import PropTypes from 'prop-types'

const Feature = ({ label, heading, description, screenshot }) => {
  return (
    <div className="relative bg-gray-700 pt-16 overflow-hidden sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-base font-semibold tracking-wider text-yellow-600 uppercase">{label}</h2>
          <p className="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
            {heading}
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-300">{description}</p>
        </div>
        <div className="mt-12">
          <img
            className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
            src={!!screenshot.childImageSharp ? screenshot.childImageSharp.fluid.src : screenshot}
            alt="QuestAid Screenshot"
          />
        </div>
      </div>
    </div>
  )
}

Feature.propTypes = {
  label: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  screenshot: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default Feature
