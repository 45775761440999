import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Header from '../components/Header'
import Feature from '../components/Feature'
import Contact from '../components/Contact'

export const IndexPageTemplate = ({ logo, hero, feature, contact }) => (
  <div className="min-h-screen">
    <div className="relative overflow-hidden">
      <Header logo={logo} />
      <main>
        <Hero {...hero} phone={contact.phone} email={contact.email} />
        <Feature {...feature} />
        <Contact {...contact} />
      </main>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hero: PropTypes.object,
  feature: PropTypes.object,
  contact: PropTypes.object
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        logo {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hero {
          heading1
          heading2
          description
          image {
            publicURL
          }
        }
        feature {
          label
          heading
          description
          screenshot {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contact {
          heading
          description
          phone
          email
        }
      }
    }
  }
`
