import React from 'react'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types'

const Hero = ({ heading1, heading2, description, image, phone, email }) => {
  return (
    <div className="pt-10 bg-gray-700 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div
            className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1
                className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">{heading1}</span>
                <span className="block text-yellow-400">{heading2}</span>
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {description}
              </p>
              <div className="mt-10 sm:mt-12">
                <div className="sm:max-w-xl sm:mx-auto lg:mx-0 flex flex-col md:flex-row justify-around items-start">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <PhoneIcon className="h-6 w-6 text-gray-200" aria-hidden="true" />
                    </div>
                    <div className="ml-3 text-base text-gray-300">
                      <p>{phone}</p>
                    </div>
                  </div>
                  <div className="mt-6 md:mt-0 flex">
                    <div className="flex-shrink-0">
                      <MailIcon className="h-6 w-6 text-gray-200" aria-hidden="true" />
                    </div>
                    <div className="ml-3 text-base text-gray-300">
                      <p>{email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <img
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={image.publicURL || image}
                alt="Hero image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


Hero.propTypes = {
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  phone: PropTypes.string,
  email: PropTypes.string
}

export default Hero
