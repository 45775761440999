import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ logo }) => {
  return (
    <div className="bg-gray-700 pt-6">
      <nav
        className="relative max-w-7xl mx-auto px-4 sm:px-6 flex justify-center"
        aria-label="Global"
      >
        <a href="/">
          <span className="sr-only">QuestAid Logo</span>
          <img
            className="h-36 w-auto"
            src={!!logo.childImageSharp ? logo.childImageSharp.fluid.src : logo}
            alt="QuestAid Logo"
          />
        </a>
      </nav>
    </div>
  )
}

Header.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default Header
