import React from 'react'
import PropTypes from 'prop-types'

/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

const Contact = ({ heading, description, phone, email }) => {
  return (
    <div className="bg-gray-700">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto">
          <div>
            <h2 className="text-2xl font-extrabold text-white sm:text-3xl">{heading}</h2>
            <div className="mt-3">
              <p className="text-lg text-gray-300">{description}</p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-gray-200" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-300">
                  <p>{phone}</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <MailIcon className="h-6 w-6 text-gray-200" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-300">
                  <p>{email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Contact.propTypes = {
  label: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string
}

export default Contact
